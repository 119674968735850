import React, { Component } from "react";
import { graphql} from "gatsby";
import axios from 'axios'
import validator from 'validator';
import ReactHtmlParser from 'react-html-parser';
import "../css/swiper-bundle.min.css";

import Swiper, { EffectCoverflow, Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';

import PopularDegreeOutcome from "../components/popular-degree-outcome-fields";

import "../css/style.css"
import "../css/lp-style.css"
import Layout from "../components/layout";
import LandingTestimonials from "../components/landingPageTestimonials";

import { Helmet } from "react-helmet";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);

class WorldgradLandingPageTemplate extends Component {

    constructor(props) {
      super(props);
      this.state = {
              isMobile: '',
              destination: '',
              qualification: '',
              firstName: '',
              number: '',
              email: '',
              policy:0, 
              target_destination:'',
              errors: {}
      };
    }
    
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
   // console.log("handleInput->"+target.name+"--"+target.value)
        this.setState({
          [name]: value, 
          target_destination: document.getElementById('target_destination').value

        });
        
    }
    

    handlePolicy = (e) => {
      const policy = e.target.checked 

      this.setState({
        policy: policy

      });
    }
    runValidations = () => {
      const { firstName, email, number, qualification, destination, policy } = this.state;
      const errors = {};
    
      
      if (!destination) {
        errors.qualification = "Please confirm if you are a Parent or Student?";
      }
      if (!qualification) {
        errors.destination = "Please confirm which course are you looking for, Bachelor’s or Master’s?";
      }
      if (firstName.trim().length === 0) {
        errors.firstName = "Please enter your Name.";
      }
      if (email.trim().length === 0) {
        errors.email = "Please enter your E-mail ID.";
      } else if (!validator.isEmail(email)) {
        errors.email = "Please enter correct E-Mail ID.";
      }
      if (number.trim().length === 0) {
        errors.number = "Please enter your Contact No.";
      } else if (number.trim().length != 10) {
        errors.number = "Please enter 10 digits Contact No.";
      }
    
      return errors;
    }

    handleSubmit = (event) => {
      
        //console.log('formsubmit');
        event.preventDefault();
        const target_destination_new = document.getElementById('target_destination').value;
        const { destination, qualification, firstName, number, email, policy } = this.state;
        const errors = this.runValidations(); 

        if (Object.keys(errors).length > 0) {
          const errorMessage = Object.values(errors).join("\n");
          alert(errorMessage);
          return;
        }else{

  const formData = {
    destination: destination,
    qualification: qualification,
    firstName: firstName,
    number: number,
    target_destination: target_destination_new,
    email: email,
    policy: policy
  };
  // Handle form submission here, e.g., send form data to server

  //console.log('destination -->'+JSON.stringify(formData));
  //swal("Done!", "Our Admissions team will get in touch with you soon.", "success");
  axios.post('https://api.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r818cc16ada0b011c2981128aabe70284&secretKey=65a4a7aea451fbd17be5958bff3d23abe3bcb92f', [
        {
          "Attribute": "FirstName",
          "Value": firstName
        },
        {
          "Attribute": "EmailAddress",
          "Value": email
        },
        {
          "Attribute": "Phone",
          "Value": number
        },
        {
          "Attribute": "SearchBy",
          "Value": "Phone"
        },
        {
          "Attribute": "mx_Student_program_level",
          "Value": qualification
        },
        {
          "Attribute": "mx_Lead_Type",
          "Value": destination 
        },
        // {
        //   "Attribute": "mx_Target_Discipline",
        //   "Value": JSON.stringify(arrSelected)
        // },
        {
          "Attribute": "ProspectID",
          "Value": window ? window.MXCProspectId ? window.MXCProspectId : "xxxxxxxx-d168-xxxx-9f8b-xxxx97xxxxxx" : "xxxxxxxx-d168-xxxx-9f8b-xxxx97xxxxxx"
        },
        // {
        //   "Attribute": "mx_Help_With",
        //   "Value": JSON.stringify(helpSelectOptions)
        // },
        // {
        //   "Attribute" : "mx_Timeline_for_Going_Abroad",
        //   "Value" : abroad
        // },
        // {
        //   "Attribute": "mx_Questions_from_Student",
        //   "Value": questions
        // },
        {
          "Attribute": "mx_Read_TWG_Privacy_Policy",
          "Value": policy 
        },
        {
          "Attribute": "Source",
          "Value": "Landing Page Enquiry"
        },
        {
          "Attribute": "mx_Target_Destination",
          "Value": target_destination_new
        },
        {
          "Attribute": "mx_Lead_Captured_at",
          "Value": "Landing Page Enquiry"
        }
      ])
      .then(response => {
       // console.log(response.status)
        //window.location.href = "/thankyou/";
        window.location.href = "https://study.theworldgrad.com/TWG-thankyou";
      })
      .catch(err => {
        alert(err.message)
       // console.log(err.message)
      })
      
      
    }

    }



    componentDidMount() {

      if (typeof window !== 'undefined') {
        // This code will only run on the client side
        this.setState({ isMobile: window.innerWidth <= 768 });
      }

      
      


      const mainElement = document.querySelector('.landing-page-main');
      const degreeSlideElement = document.querySelector('.degree-slide');



      document.addEventListener('click', this.handleDocumentClick);
      
      if (mainElement) {
        // Check if mainElement exists with the class 'landing-page-main'
        if (degreeSlideElement) {
          document.querySelector('.degree-slide').closest('div').classList.add('newStyle');
        }

        const programLinks = mainElement.querySelectorAll('a.programlink');
        programLinks.forEach(link => {
          // Change the href attribute to #
          link.setAttribute('href', '#top');
          
          // Add an onclick event handler
          link.onclick = function(event) {
            // Prevent the default link behavior
            event.preventDefault();
            
            // Check if the href attribute is #
            if (link.getAttribute('href') === '#top' && link.classList.contains('programlink')) {
              // Scroll to the top of the page
              window.scrollTo({
                top: 0,
                behavior: 'smooth' // Add smooth scrolling effect
              });
            }
          };
        });
      }
      

      const validIds = [
        'study-in-uk',
        'masters-in-the-usa-increase-your-visa-chances-by-50',
        'bachelors-in-the-usa-increase-your-visa-chances-by-50',
        'masters-in-the-usa-from-inr-20-lakhs',
        'study-bachelors-in-the-usa-no-sat-lor-required',
        'the-most-cost-effective-route-to-a-bachelors-degree-in-the-usa',
        'make-the-1st-semester-of-your-masters-in-us-free',
        'masters-in-the-usa-without-gmat-gre',
        'bachelors-in-the-uk-usa-australia',
        'study-in-australia',
        'study-in-usa',
        'masters-in-the-usa',
        'ms-cybersecurity-drexel-university',
        'study-ms-in-the-usa',
        'study-mba-in-the-usa',
        'save-inr-60-lacs-on-a-globally-ranked-bachelors-in-the-us',
        'cost-saving-affordability',
        'bachelors-exclusive-scholarships-tailored-just-for-you',
        'masters-exclusive-scholarships-tailored-just-for-you',
        'study-mba-in-the-usa',
        'bachelors-in-usa-affordable-savings',
        'bachelors-in-usa-scholarships-for-your-child',
        'bachelors-in-usa-with-scholarship',
        'masters-in-usa-with-scholarship',
        'study-ms-in-the-usa',
        'bachelors-in-usa-with-scholarships-for-your-child'
        
      ];

      
      const validMastersIds = [
        'ms-cybersecurity-drexel-university',
        'masters-in-the-usa',
        'masters-in-the-usa-increase-your-visa-chances-by-50',
        'masters-in-the-usa-from-inr-20-lakhs',
        'make-the-1st-semester-of-your-masters-in-us-free',
        'masters-in-the-usa-without-gmat-gre',
        'study-ms-in-the-usa',
        'study-mba-in-the-usa',
        'masters-exclusive-scholarships-tailored-just-for-you',
        'study-mba-in-the-usa',
        'masters-in-usa-with-scholarship',
        'study-ms-in-the-usa'
      ];
      
      if (mainElement && validIds.includes(mainElement.id)) {
        let degreeInput;
        if (validMastersIds.includes(mainElement.id)) {
          // console.log('ms-cybersecurity-drexel-university 1->' + mainElement.id);
          degreeInput = document.querySelector('input[value="Postgraduate"]');
        } else {
          // console.log('ms-cybersecurity-drexel-university 2->' + mainElement.id);
          degreeInput = document.querySelector('input[value="Undergraduate"]');
        }
        if (degreeInput) {
          degreeInput.click();
        }
  
        const degreeDiv = document.querySelector('#lp-frm-step-2');
        if (degreeDiv) {
          degreeDiv.style.display = 'none';
        }
      }
      

if (
  mainElement &&
  (mainElement.id != 'bachelors-in-the-uk-usa-australia' )
) {
 const degreeDiv = document.querySelector('#lp-frm-step-4');
  if (degreeDiv) {
    degreeDiv.style.display = 'none';
  }
} 





      const swiper2 = new Swiper(".lp-logo-slider", {
        slidesPerView: 2,
        loop: false,
        mousewheel: false,
        keyboard: {
            enabled: true
        },
        autoplay: { 
          delay: 3000, 
          disableOnInteraction: false 
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
            1366: {
                slidesPerView: 5,
            }
        }
    });


      var swiper = new Swiper('.lp-testimonials-box', {

        centeredSlides: false,
              loop: false,
              slidesPerView: 1,
              spaceBetween: 0,
              slidesPerGroup: 1,
              autoplay: {
                delay: 7000,
                disableOnInteraction: false
              },
              pagination: {
                el: '.swiper-pagination-testimonial',
                clickable: true,
              },
              navigation: {
                nextEl: '.swiper-next-testimonial',
                prevEl: '.swiper-prev-testimonial',
              },
              
              breakpoints: {
                1200: { slidesPerGroup: 3, slidesPerView: 3, spaceBetween: 20},
                991: { slidesPerGroup: 2, slidesPerView: 2},
                768: { slidesPerGroup: 1, slidesPerView: 1 }
              }
      });

        

     


        const radioStep1 = document.getElementsByName("destination");
const radioStep2 = document.getElementsByName("qualification");
const radioStep4 = document.getElementsByName("target_destination_2");
const step2 = document.getElementById("lp-frm-step-2");
const step3 = document.getElementById("lp-frm-step-3");
const step4 = document.getElementById("lp-frm-step-4");

// Add event listeners to the radio buttons in step 1
radioStep1.forEach((radio) => {
  radio.addEventListener("change", function () {
 
    step2.classList.add("open");

    const selectedRadioButton = document.querySelector('input[name="qualification"]:checked');

    const step4Display = document.querySelector('#lp-frm-step-4').style.display;
    
    if (document.querySelector('#lp-frm-step-4').style.display == "none") {
      if (selectedRadioButton && selectedRadioButton.value) {
        step3.classList.add("open");
    }
    } else {
      step4.classList.add("open");
    }



    
  });
});

// Add event listeners to the radio buttons in step 2

radioStep2.forEach((radio) => {
  radio.addEventListener("change", function () {
    const step4Display = document.querySelector('#lp-frm-step-4').style.display;
    
    if (document.querySelector('#lp-frm-step-4').style.display == "none") {
      step3.classList.add("open");
    } else {
      step4.classList.add("open");
    }
  });
});


// Add event listeners to the radio buttons in step 2
radioStep4.forEach((radio) => {
  radio.addEventListener("change", function () {
    const targetDestinationElement = document.getElementById("target_destination");
    targetDestinationElement.value = radio.value;
    step3.classList.add("open");
  });
});

/*        
       const header = document.querySelector('.wrapper');
  if (header) {
    header.style.display = 'none';
  }

        const footer = document.querySelector('#footer');
  if (footer) {
    footer.style.display = 'none';
  }

*/
    }
	handlePopupWindow = () => {
		this.setState({ time: Math.random() });
	};
	// TableContent = () => {
	// 	let wrapperElement = document.querySelector('.blog-detail-topic-para-text')
	// 	console.log(wrapperElement.innerHTML)
	// }
	// TableContent();
  componentWillUnmount() {
    // Remove event listener when component unmounts
    document.removeEventListener('click', this.handleDocumentClick);
  }

	render() {
		var filteredRes = [];
        //console.log('dynamic_schema->'+JSON.stringify(this.props));
		const post = this.props.data.wordpressWpLandingPage;
		const metatitle = post.acf.meta_title ;
		const metadescription = post.acf.meta_description; 
	    const focus_keyphrase = post.acf.focus_keyphrase; 
	    const canonical_url = `https://theworldgrad.com${this.props.path}`; 
      //const process_steps = this.props.data.allWordpressPage.nodes[0].acf.process_steps;
      const process_steps = post.acf.process_steps;
      const comparison = post.acf.comparison?.slice(0, 4);
      

		var headerBackground = "/static/americanbanner-31161936b50af35a68b688be75f818f4.png";
        if(post.featured_media){
        	headerBackground = post.featured_media.source_url;
        }
     
        const successRateBackgroundImage = {
          backgroundImage: `url(${
            this.state.isMobile &&
            post?.acf?.success_rate_map_mobile?.source_url
              ? `${post?.acf?.success_rate_map_mobile?.source_url}?mobile`
              : post?.acf?.success_rate_map?.source_url
          })`
        };

        const footerCtaImageUrl = this.state.isMobile && post?.acf?.footer_cta_background_image_mobile?.source_url
  ? `${post?.acf?.footer_cta_background_image_mobile?.source_url}?mobile`
  : post?.acf?.footer_cta_background_image?.source_url || 'https://assets.theworldgrad.com//wp-content/uploads/2023/06/new-york-city-3.jpg';

  const speakToExpertSectionStyle = {
    backgroundImage: `url(${footerCtaImageUrl})`,
  };


		return (
			<div>
				<Layout>
					<Helmet>
					<title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          <meta name="referrer" content="no-referrer-when-downgrade"></meta>
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          <link rel="canonical" href={canonical_url} /> 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          


					</Helmet>
 {/*canonical_url*/}
					{/* <Header /> */}
					
					<main id={post.slug} className={`landing-page-main ${post.acf.header_image_desktop?.source_url && post.acf.header_image_mobile?.source_url ? 'newheader-lp' : ''}`}>


        
            <section class="lp-banner-sec">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 lp-banner-text">
                            <a href="https://theworldgrad.com/" class="lp-banner-logo"><img src="https://assets.theworldgrad.com//gatsby-assets/images/logo.png" alt="The WorldGrad" /></a>
                           
    {post.acf.header_image_desktop?.source_url && post.acf.header_image_mobile?.source_url ? (
       <>
       <h1 class="newheader">{ReactHtmlParser(post.acf.title)}</h1>


               <div className="new-lp-image">
      
  {this.state.isMobile && post?.acf?.header_image_mobile?.source_url ? (
    <img
      src={post.acf.header_image_mobile.source_url}
      alt={ReactHtmlParser(post.acf.title)}
    />
  ) : (
    <img
      src={post.acf.header_image_desktop?.source_url}
      alt={ReactHtmlParser(post.acf.title)}
    />
  )}



 

       <div className="lp-banner-clr-title">
        
        <div className="lp-banner-bg-box">
            <span className="bg-4"></span>
            <span className="bg-5"></span>
        </div>
        
        </div>
        
</div>
        </>
    ) : (
        <>
        <h1>{ReactHtmlParser(post.acf.title)}</h1>
                            
            <div className="lp-banner-clr-title">
            <div className="lp-banner-bg-box">
                <span className="bg-1"></span>
                <span className="bg-2"></span>
                <span className="bg-3"></span>
            </div>
            <p className="">{ReactHtmlParser(post.acf.tricolor_2_title)}</p>
            </div>
        </>
    )}



                            
                        </div>
                        <div class="col-lg-6 lp-banner-form-holder">
                        
                             
                            <div id="lp-banner-form" class="lp-banner-form">
                         
                            <div id="lp-frm-step-1" className="lp-banner-form-box open">
          <div className="lp-banner-form-title">You are a</div>
          <div className="lp-banner-form-detail">
            <div className="radio-btn-style-1 ">
              <label className="custom-radio">
                <input
                  type="radio"
                  name="destination"
                  value="Student"
                  checked={this.state.destination === 'Student'}
                  onChange={this.handleInputChange}
                />
                <span className="radio-btn">
                  <img src="https://assets.theworldgrad.com//wp-content/uploads/2023/06/students.png" alt="Student" height="32" width="32" />
                  <p className="">Student</p>
                </span>
              </label>
              <label className="custom-radio">
                <input
                  type="radio"
                  name="destination"
                  value="Parent"
                  checked={this.state.destination === 'Parent'}
                  onChange={this.handleInputChange}
                />
                <span className="radio-btn">
                  <img src="https://assets.theworldgrad.com//wp-content/uploads/2023/06/parents.png" alt="Parent" height="32" width="32"  />
                  <p className="">Parent</p>
                </span>
              </label>
            </div>
          </div>
        </div>
      
        <div id="lp-frm-step-2" className="lp-banner-form-box">
          <div className="lp-banner-form-title">Which course are you looking for?</div>
          <div className="lp-banner-form-detail">
            <div className="radio-btn-style-2 radio-btn-style-1">
              <label className="custom-radio">
                <input
                  type="radio"
                  name="qualification"
                  value="Undergraduate"
                  checked={this.state.qualification === 'Undergraduate'}
                  onChange={this.handleInputChange}
                />
                <span className="radio-btn">
                  <p className="">Bachelor's</p>
                </span>
              </label>
              <label className="custom-radio">
                <input
                  type="radio"
                  name="qualification"
                  value="Postgraduate"
                  checked={this.state.qualification === 'Postgraduate'}
                  onChange={this.handleInputChange}
                />
                <span className="radio-btn">
                  <p>Master’s</p>
                </span>
              </label>
            </div>
          </div>
        </div>
       

          <div id="lp-frm-step-4" className="lp-banner-form-box">
            <div className="lp-banner-form-title">Preferred destination country</div>
          <div className="lp-banner-form-detail">
            <div className="radio-btn-style-2 radio-btn-style-1">
              <label className="custom-radio">
                <input
                  type="radio"
                  name="target_destination_2"
                  value="Australia"
                  checked={this.state.target_destination_2 === 'Australia'}
                  onChange={this.handleInputChange}
                />
                <span className="radio-btn">
                  <p className="">Australia</p>
                </span>
              </label>

              <label className="custom-radio">
                <input
                  type="radio"
                  name="target_destination_2"
                  value="United Kingdom"
                  checked={this.state.target_destination_2 === 'United Kingdom'}
                  onChange={this.handleInputChange}
                />
                <span className="radio-btn">
                  <p>UK</p>
                </span>
              </label>
              <label className="custom-radio">
                <input
                  type="radio"
                  name="target_destination_2"
                  value="United States"
                  checked={this.state.target_destination_2 === 'United States'}
                  onChange={this.handleInputChange}
                />
                <span className="radio-btn">
                  <p>USA</p>
                </span>
              </label>

            </div>
          </div>
        </div>

          

        <div id="lp-frm-step-3" className="lp-banner-form-box">
          <div className="lp-banner-form-title">Please share your details <span>We Promise No Spam!</span></div>
          <div className="lp-banner-form-detail">
            <div className="detail-from-box d-flex ">
              <div className="lp-frm-name w-100">
                <input
                  type="text"
                  name="firstName"
                  placeholder="Name"
                  value={this.state.firstName}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="detail-from-box d-flex">
              <div className="lp-frm-phone">
                <input
                  type="number"
                  name="number"
                  placeholder="Contact No."
                  value={this.state.number}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="lp-frm-email">
                <input
                  type="text"
                  name="email"
                  placeholder="Email ID"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="lp-frm-privacy">
                  <input
                    checked={this.state.policy}
                    onChange={this.handlePolicy}
                   
                    type="checkbox"
                    id="newsletter-off"
                  />

                  <label for="newsletter-off" class="text-very-small privacy-label" >I have read and understood <a href="/privacypolicy">The WorldGrad Privacy Policy </a></label>

                </div>
        <div className="detail-from-box-sb">
        <input id="target_destination" type="hidden" name="target_destination" value={post.acf.country_name} />
          <input type="submit" value="Submit" onClick={this.handleSubmit} />
        </div>


                             
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section class="lp-statistic-sec">
                <div class="container container-1400">
                    <div class="row text-center">
                        <div class="col-md-3  col-6 lp-statistic-box">
                            <p>{ReactHtmlParser(post.acf.blue_box_1_text)}</p>
                        </div>
                        <div class="col-md-3  col-6 lp-statistic-box">
                            <p>{ReactHtmlParser(post.acf.blue_box_2_text)}</p>
                        </div>
                        <div class="col-md-3 col-6 lp-statistic-box">
                            <p>{ReactHtmlParser(post.acf.blue_box_3_text)}</p>
                        </div>
                        <div class="col-md-3 col-6 lp-statistic-box">
                            <p>{ReactHtmlParser(post.acf.blue_box_4_text)}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="lp-award-testimonial-sec postion-relative">
              <div class="lp-curcle-element" ><img src="https://assets.theworldgrad.com//wp-content/uploads/2023/06/Subtract.png" class="w-100" alt="Subtract" height="772" width="250"/></div>
                <div class="lp-award--box">
                    <div class="container">
                        <div class="row text-center">
                            <div class="col-md-12 text-center">
                                <h2>{ReactHtmlParser(post.acf.award_title)}</h2>
                                {post.acf.award_title && (
                                  <h3 class="pb-4 degree-label">Popular Degrees via The WorldGrad</h3>

)}
                            </div>
</div>
{post.acf.popular_degree_outcome && (
  <PopularDegreeOutcome
    programLink="top"
    degrees={post.acf.popular_degrees} isMobile={this.state.isMobile}
  />
)}

                        </div>
                    </div>
              
                <div class="lp-testimonial--box">
                    <div class="container">
                        <div class="row ">
                            <div class="col-md-12">
                                <h3>{ReactHtmlParser(post.acf.testimonial_title)}</h3>
                            </div>
                        
        <LandingTestimonials landingTestimonials={post.acf.testimonials} />

        
    
                        </div>
                    </div>
                </div>
            </section>
            
            <section class="lp-about-sec">
                <div class="container">
                    <div class="row ">
                        <div class="col-lg-7 lp-banner-text">
                        <div className="lp-banner-clr-title">
                          
  
        <div className="lp-banner-bg-box">
            <span className="bg-1"></span>
            <span className="bg-2"></span>
            <span className="bg-3"></span>
        </div>
          <p className="">{ReactHtmlParser(post.acf.tricolor_2_title)}</p>


</div>
                        </div>
                    </div>
                    <div class="row align-items-center ">
                        <div class="col-lg-7 lp-about-text">
                        <img
      src={
        (this.state.isMobile && post?.acf?.smart_program_image_mobile?.source_url) ?
        `${post?.acf?.smart_program_image_mobile?.source_url}?mobile` :
        (post?.acf?.smart_program_image?.source_url || "https://assets.theworldgrad.com//wp-content/uploads/2023/06/lg-about-img.png??")
      }
      alt="TWG Smart Program"
      className="lp-about-img-mobile" height="620" width="445"
    />
                       
                            <p>{ReactHtmlParser(post.acf.smart_program_content)}</p>
                        
                            {process_steps && process_steps.length !== 0 && (
  <div class="col-12 process-step-style-home-page newStyle">
    {process_steps.map((process, index) => (
      <div class="process-step-style-02 wow animate__fadeIn index" data-wow-delay="0.2s">
        <div class="process-step-item">
          <div class="process-step-icon-wrap">
            <div class="process-step-icon text-center border-all border-color-fast-blue border-width-2px bg-fast-blue alt-font font-weight-500">{index + 1}</div>
            <span class="process-step-item-box-bfr bg-extra-dark-gray-2"></span>
          </div>
          <div class="process-content last-paragraph-no-margin">
            <span class="alt-font d-block font-weight-500 text-extra-dark-gray margin-5px-bottom">{process.process_step_title}</span>
            <p class="w-100 xs-w-100 text-medium">{ReactHtmlParser(process.process_step_description)}</p>
          </div>
        </div>
      </div>
    ))}
  
    <br/>
    
  </div>
)}

                            
                            <a id="speak-to-an-expert" href={post.acf.cta_button_1_url} class="lp-btn" data-animation="animated zoomInUp ">{post.acf.cta_button_1_text}</a>
                        </div>
                        <div class="col-lg-5 d-none-mobile">
                        <img src={post?.acf?.smart_program_image?.source_url || "https://assets.theworldgrad.com//wp-content/uploads/2023/06/lg-about-img.png??"} alt={ReactHtmlParser(post.acf.title)} class="lp-about-img" />

                        </div>
                    </div>
                </div>
            </section>
            {comparison && comparison.length !== 0 && comparison.length == 4 && (
            <section class="bg-orng lp-comparison-section">
                <div class="container container-1400">
                    <div class="row text-center">
                        <div class="col-md-12">
                            <div class="lp-comparison-holder">
                                
                                    
                                      <div class="lp-comparison-box lp-comparison-box-first">
                                      <div class="lp-comparison-box-title">
                                      </div>
                                        {comparison.map((comparison_text, index) => (
                                          <div class="lp-comparison-box-text">
                                          <p>{ReactHtmlParser(comparison_text.label)}</p>
                                          </div>
                                        ))}
                                        </div>
                                    
                                    
                                
                                <div class="lp-comparison-box lp-comparison-box-direct">
                                    <div class="lp-comparison-box-title">
                                      <h3>{ReactHtmlParser(post.acf.comparison_title_1)}</h3>
                                    </div>
                                    {comparison.map((comparison_text, index) => (
                                      <div class="lp-comparison-box-text">
                                         <div>
                                         <p>{ReactHtmlParser(comparison_text.label)}</p>
                                         <h4>{ReactHtmlParser(comparison_text.direct_text)}</h4>
                                          </div>
                                        </div>
                                    ))}

                                    
                                </div>
                                <div class="lp-comparison-box lp-comparison-box-twg">
                                   <div class="lp-comparison-box-title">
                                      <h3>{ReactHtmlParser(post.acf.comparison_title_2)}</h3>
                        
                                    </div>

                                    {comparison.map((comparison_text, index) => (
                                      <div class="lp-comparison-box-text">
                                         <div>
                                         <p>{ReactHtmlParser(comparison_text.label)}</p>
                                         <h4>{ReactHtmlParser(comparison_text.twg)}</h4>
                                          </div>
                                          <img src="https://assets.theworldgrad.com//wp-content/uploads/2023/09/Line-7.png" alt="---" class="arrow-comparison" />
                                        </div>
                                    ))}


                                </div>
                                <div class="lp-comparison-box lp-comparison-box-twg-advance">
                                <div class="lp-comparison-box-title">
                                      <h3>{ReactHtmlParser(post.acf.comparison_title_3)}</h3>
                                    </div>

                                    {comparison.map((comparison_text, index) => (
                                      <div class="lp-comparison-box-text">
                                      <h4>{ReactHtmlParser(comparison_text.advantage)}</h4>
                                     </div>
                                    ))}
                                    
                                    
                                </div>
                            </div>
                            <div class="lp-disclaimer"><p>*For select universities</p></div>
                        </div>
                    </div>
                </div>
            </section>
)}

            <section class="lp-btm-statistic-sec">
                <div class="container container-1400">
                    <div class="row text-center align-items-center">
                        <div class="col-md-5 lp-btm-statistic-left">
                            <div class="lp-btm-statistic-box">
                                <h3><CountUp end={post.acf.counter_1_count} redraw={true}  duration={1}>
                                {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
            </VisibilitySensor>
        )}
                                </CountUp>+</h3>
                                <p>{ReactHtmlParser(post.acf.counter_1_text)}</p>
                            </div>
                            <div class="lp-btm-statistic-box">
                                <h3><CountUp end={post.acf.counter_2_count} redraw={true}  duration={1}>
                                {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
            </VisibilitySensor>
        )}
                                </CountUp>+</h3>
                                <p>{ReactHtmlParser(post.acf.counter_2_text)}</p>
                            </div>
                            <div class="lp-btm-statistic-box">
                                <h3><CountUp end={post.acf.counter_3_count} redraw={true}  duration={1}>
                                {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
            </VisibilitySensor>
        )}
                                </CountUp>+</h3>
                                <p>{ReactHtmlParser(post.acf.counter_3_text)}</p>
                            </div>
                        </div>
                        <div class="col-md-7 lp-btm-statistic-right">
                        <div class="lp-btm-statistic-right-box" style={successRateBackgroundImage}>
     <p>{post.acf.success_rate_small_heading}</p>
                                <h2><CountUp end={post.acf.success_rate_count} redraw={true} duration={1} >
                                {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
            </VisibilitySensor>
        )}
                                </CountUp>%</h2>
                                <h3>{post.acf.success_rate_large_heading}</h3>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section class="lp-speak-to-expert-sec text-center" style={speakToExpertSectionStyle}>
  <a id="speak-to-an-expert-footer" href={post.acf.footer_cta_url} class="lp-btn lp-btn-large wow fadeInUp animated" data-wow-duration="1s" data-wow-delay=".1s"  data-animation="animated zoomInUp">{post.acf.footer_cta_text}</a>
</section>

        </main>
        <div class="newStyle lp-footer">
            <footer class="footer-dark bg-fast-blue pb-0 ">
            <div class="footer-top padding-40px-tb border-bottom border-color-white-transparent mb-0"><div class="container"><div class="row align-items-center"><div class="col-12 col-md-3  text-sm-start sm-margin-20px-bottom"><a href="/" class="footer-logo"><img src="https://assets.theworldgrad.com//gatsby-assets/images/logo-white.png" data-at2x="https://assets.theworldgrad.com//gatsby-assets/images/logo-white.png" alt="The WorldGrad" height="45" /></a></div><div class="col-12 col-md-6 text-md-center sm-margin-20px-bottom"><span class="alt-font font-weight-500 d-inline-block align-middle margin-5px-right text-uppercase text-white min-17px-1200">The Smartest Way To Study Overseas</span></div><div class="col-12 col-md-3 text-center text-md-end"><div class="social-icon-style-12">
              <ul class="extra-small-icon footer-social-new-icons light">                
                <li><a class="linkedin" href="https://www.linkedin.com/company/the-worldgrad/mycompany/" target="_blank"><svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.000627064 17.5403C0.000627064 16.8639 0.000627064 16.1881 0.000627064 15.5117C0.0263116 15.304 0.0538769 15.0963 0.0764291 14.888C0.446662 11.4508 1.72901 8.41114 3.9905 5.81987C6.47751 2.97075 9.57532 1.19878 13.2802 0.543982C13.8521 0.442999 14.4303 0.379488 15.006 0.298828C15.6732 0.298828 16.3397 0.298828 17.0069 0.298828C17.2118 0.324868 17.4166 0.353448 17.6215 0.375677C20.9918 0.746584 23.995 2.01173 26.5177 4.30894C30.7394 8.15392 32.5529 12.9846 31.8538 18.6892C31.317 23.0708 29.2321 26.644 25.7917 29.3489C22.2716 32.1168 18.2561 33.178 13.8452 32.6001C10.6409 32.1803 7.8175 30.8516 5.4044 28.6713C2.63611 26.1689 0.906478 23.0619 0.248703 19.3548C0.142207 18.7546 0.0814388 18.1455 0 17.5403H0.000627064ZM25.3945 24.8193C25.3995 24.698 25.4076 24.6046 25.4076 24.5113C25.4076 22.3671 25.4127 20.2236 25.4039 18.0795C25.402 17.6266 25.3726 17.1713 25.3181 16.7222C25.0362 14.4072 23.668 12.9522 21.4673 12.695C19.8742 12.5089 18.5254 12.9973 17.5739 14.3818C17.5419 14.4282 17.4993 14.4676 17.4041 14.5749V12.9554H13.5314V24.8104H17.4091V24.4173C17.4091 22.8225 17.4072 21.2277 17.4104 19.633C17.4116 19.0207 17.4097 18.4078 17.4354 17.7962C17.4661 17.0804 17.8226 16.5317 18.3876 16.1328C19.5115 15.339 20.9642 15.8445 21.3363 17.1744C21.4591 17.6139 21.5005 18.089 21.5042 18.5488C21.5218 20.5234 21.5117 22.4986 21.5117 24.4738C21.5117 24.5856 21.5117 24.6974 21.5117 24.8193H25.3951H25.3945ZM7.46731 24.8079H11.3469C11.3519 24.7247 11.3595 24.6631 11.3595 24.6015C11.3595 20.8092 11.3576 17.0169 11.3651 13.2247C11.3651 12.9757 11.2786 12.9274 11.0563 12.93C9.95182 12.9395 8.84738 12.9338 7.74295 12.9357C7.65274 12.9357 7.56253 12.9503 7.46794 12.9579V24.8085L7.46731 24.8079ZM7.23302 9.27489C7.23928 10.4778 8.15202 11.3308 9.42058 11.32C10.6973 11.3092 11.6251 10.4416 11.615 9.26727C11.605 8.05166 10.6866 7.19807 9.40054 7.20887C8.12132 7.21966 7.22675 8.07262 7.23302 9.27489Z" fill="white"/>
</svg></a></li>
                <li><a class="facebook" href="https://www.facebook.com/The-WorldGrad-104434275220410/" target="_blank"><svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 17.5396C0 16.863 0 16.1873 0 15.5115C0.0250454 15.3041 0.0534302 15.0966 0.075971 14.8883C0.452487 11.3817 1.77739 8.29344 4.11162 5.67683C6.5811 2.90781 9.63747 1.18288 13.2732 0.543549C13.8451 0.44278 14.4228 0.379274 14.998 0.298828C15.6642 0.298828 16.3313 0.298828 16.9975 0.298828C17.3782 0.345402 17.7597 0.386047 18.1387 0.440242C21.5724 0.933924 24.5871 2.34892 27.0549 4.82242C31.2183 8.99543 32.7903 14.0601 31.6282 19.87C30.6355 24.8348 27.7812 28.4895 23.3991 30.9105C22.4666 31.4254 21.4806 31.8175 20.4487 32.1088V20.1113H23.8649C24.0377 18.7573 24.208 17.4244 24.3834 16.0501H20.4487C20.4487 14.9696 20.4045 13.9229 20.4638 12.8831C20.5072 12.1116 20.9246 11.6874 21.681 11.5765C22.2637 11.4909 22.8631 11.5163 23.455 11.5003C23.7956 11.4909 24.1362 11.4986 24.4785 11.4986V7.84802C23.7698 7.80483 23.076 7.72523 22.3822 7.73116C21.5741 7.73794 20.7451 7.71931 19.962 7.88867C17.8014 8.35694 16.4298 10.0692 16.3171 12.3631C16.2628 13.4589 16.2887 14.5589 16.2787 15.6572C16.2778 15.7808 16.2787 15.9036 16.2787 16.0628H12.8692V20.1325H16.2553V32.7303C16.2019 32.7396 16.171 32.7481 16.1401 32.7489C16.0566 32.7515 15.9731 32.7532 15.8905 32.7523C13.5796 32.7278 11.3581 32.2688 9.27765 31.2416C4.33035 28.8012 1.31238 24.8348 0.249619 19.3543C0.133575 18.7556 0.081815 18.1442 0 17.5396Z" fill="white"/>
</svg></a></li>
                <li><a class="instagram" href="https://www.instagram.com/theworldgrad/" target="_blank"><svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32.5561 15.5142V17.4788C32.5386 17.5804 32.5169 17.6812 32.5027 17.7836C32.4159 18.4201 32.3608 19.0634 32.2406 19.6941C30.9987 26.2237 25.7838 31.388 19.3146 32.5037C18.7328 32.6044 18.1453 32.6704 17.5602 32.7525H15.5604C15.4811 32.7339 15.4027 32.7043 15.3234 32.6984C13.8928 32.596 12.504 32.2887 11.1577 31.796C3.24786 28.9045 -1.1006 20.3458 1.1646 12.1342C3.97649 1.94613 15.517 -2.82965 24.5761 2.50565C28.8461 5.02051 31.4126 8.82624 32.3157 13.7637C32.4217 14.3427 32.4776 14.931 32.5569 15.515L32.5561 15.5142ZM16.5728 24.6408C17.6136 24.6408 18.6552 24.6425 19.696 24.6408C22.4662 24.6357 24.5536 22.5196 24.5578 19.7093C24.5611 17.5863 24.5611 15.4634 24.5578 13.3405C24.5536 10.548 22.4653 8.42247 19.7194 8.41739C17.6161 8.41316 15.5128 8.414 13.4095 8.41739C10.6603 8.42162 8.56699 10.5454 8.56365 13.332C8.56031 15.4651 8.56031 17.599 8.56365 19.7322C8.56782 22.501 10.6619 24.6315 13.3862 24.6408C14.4478 24.6442 15.5103 24.6408 16.572 24.6408H16.5728Z" fill="white"/>
<path d="M10.1773 16.5326C10.1773 15.4872 10.1748 14.4418 10.1773 13.3964C10.1831 11.4097 11.5177 10.0596 13.4749 10.0579C15.5365 10.0562 17.5972 10.0562 19.6587 10.0579C21.6009 10.0596 22.9388 11.408 22.9438 13.3761C22.9497 15.4779 22.9497 17.5788 22.9438 19.6806C22.9388 21.6478 21.6043 22.9996 19.6629 23.0047C17.6013 23.0097 15.5406 23.0089 13.4791 23.0047C11.5177 23.0013 10.1839 21.652 10.1773 19.6687C10.1739 18.6233 10.1773 17.5779 10.1773 16.5326ZM16.5581 12.3391C14.2695 12.34 12.4333 14.1997 12.4283 16.5224C12.4233 18.8553 14.2661 20.7217 16.5756 20.7209C18.8391 20.7209 20.6878 18.8485 20.692 16.5529C20.6962 14.2115 18.8583 12.3383 16.5589 12.3391H16.5581ZM20.7262 13.3651C21.2838 13.3634 21.7395 12.9063 21.7428 12.3434C21.747 11.7695 21.2779 11.2929 20.7137 11.2971C20.162 11.3014 19.7005 11.7652 19.6946 12.3239C19.6879 12.897 20.1537 13.3668 20.7262 13.3659V13.3651Z" fill="white"/>
<path d="M16.5555 19.0852C15.1575 19.0818 14.0407 17.9492 14.0391 16.5305C14.0374 15.1009 15.1725 13.9573 16.5805 13.9708C17.981 13.9844 19.0894 15.1229 19.0811 16.5407C19.0728 17.9611 17.9535 19.0886 16.5555 19.0852Z" fill="white"/>
</svg></a></li>
                <li><a class="instagram" href="https://www.youtube.com/channel/UCc_ezIRLC6YKiUXV4rhgrog" target="_blank"><svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.4111 32.7525H15.4119C15.3435 32.7348 15.2751 32.7052 15.2058 32.7001C13.8508 32.6028 12.5233 32.3497 11.2491 31.8708C5.44014 29.6892 1.91569 25.5098 0.67162 19.3586C0.551469 18.7629 0.501407 18.1519 0.417969 17.5486C0.417969 16.8724 0.417969 16.1972 0.417969 15.521C0.436325 15.4288 0.459686 15.3382 0.473036 15.2452C0.579838 14.5157 0.644087 13.777 0.797614 13.0577C2.70252 4.16056 11.5962 -1.43971 20.2622 0.788384C28.5844 2.92847 33.7309 11.152 32.093 19.6912C30.8397 26.2265 25.634 31.3868 19.1633 32.5021C18.5817 32.6028 17.9935 32.6688 17.4086 32.7509L17.4111 32.7525ZM15.9751 22.4998C17.5062 22.4397 18.6093 22.4143 19.7107 22.3458C20.4783 22.2984 21.2584 22.2662 22.0027 22.0953C23.1033 21.8431 23.9151 21.2347 24.1196 19.984C24.4884 17.7237 24.4984 15.4694 24.1504 13.2092C24.0645 12.6523 23.9035 12.1455 23.4838 11.7486C22.9714 11.2646 22.3657 10.9751 21.6756 10.8981C18.8646 10.5842 16.0469 10.5351 13.2258 10.7196C12.4173 10.7729 11.5963 10.8059 10.8086 10.9794C9.78229 11.2053 8.95041 11.7579 8.74014 12.9121C8.32211 15.2062 8.31461 17.5071 8.67423 19.8105C8.76184 20.3741 8.90618 20.9004 9.33506 21.3075C9.8457 21.7932 10.4473 22.1105 11.1382 22.1664C12.8904 22.3085 14.6459 22.4101 15.9768 22.4989L15.9751 22.4998Z" fill="white"/>
<path d="M14.5117 19.6375V13.4355C16.1822 14.4755 17.8126 15.4902 19.4905 16.5344C17.8167 17.5769 16.1872 18.5932 14.5117 19.6366V19.6375Z" fill="white"/>
</svg></a></li>
                </ul>
                </div></div></div></div></div>
            </footer>
        </div>
					{/* <Footer /> */}
				</Layout>
			</div>
		);
	}

}

export default WorldgradLandingPageTemplate;
export const query = graphql`
	query LandingPageInfo($id: String!) {
		wordpressWpLandingPage(id: { eq: $id }) {
            
			id
			title
			slug
			featured_media {
				source_url
			}
      acf {
        meta_title
        meta_description
        focus_keyphrase
        canonical_url
        dynamic_schema
        title
        tricolor_title
        header_image_desktop {
          source_url
        }
        header_image_mobile {
          source_url
        }
        blue_box_1_text
        blue_box_2_text
        blue_box_3_text
        blue_box_4_text
        award_title
        awards {
          award_image {
          source_url
          }
        }
        popular_degree_outcome
        popular_degrees {
          college_university_name
           college_university_logo {
						source_url
          }
          degree_name
          country_name
          url
          degree_banner {
source_url
          }
          program_banner_image_mobile 
          {
            source_url
           }
          
        }
        
       
       
       
        testimonial_title
        testimonials {
        testimonial_image {
          source_url
        }
        scholership_image {
          source_url
                      }
       country_flag {
          source_url
                      }
        program_name
        youtube_video_link

          testimonial_content
          candidate_name
          location
          batch_detail
          
        }
        tricolor_2_title
        smart_program_content
        smart_program_image {
source_url
        }
        smart_program_image_mobile {
          source_url
         }
        cta_button_1_text
        cta_button_1_url
        counter_1_count
        counter_1_text
        counter_2_count
        counter_2_text
        counter_3_count
        counter_3_text
        success_rate_small_heading
        success_rate_count
        success_rate_large_heading
        success_rate_map {
          source_url
        }
        success_rate_map_mobile {
          source_url
         }
        country_name
        footer_cta_text
        footer_cta_url
        footer_cta_background_image {
          source_url
        }
        footer_cta_background_image_mobile {
          source_url
         }
        process_steps {
          process_step_title
          process_step_description
        }
        comparison_title_1
        comparison_title_2
        comparison_title_3
        comparison {
          advantage
          direct_text
          label
          twg
        }
      }
         
		}
	
    allWordpressWpTestimonial(filter: {}, sort: {order: ASC, fields: id}) {
        edges {
          node {
            wordpress_id
            title
            content
            featured_media {
              id
              source_url
            }
            status
            slug
            acf {
              batch_of_year
              review_rating
              state
              country_flag {
                id
                link
                source_url
                title
                type
                status
                slug
              }
            }
          }
        }
      }
    }
`;

